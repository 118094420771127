import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/src/components/mdxPageLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Fingerprint`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`4C71 7BDC 0E45 2F5B 1349 0409 AB4C 64C5 8BA9 C3ED
`}</code></pre>
    <h2>{`Public Key`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-text"
      }}>{`-----BEGIN PGP PUBLIC KEY BLOCK-----

mQINBFlH/DQBEADAeJyzOLiphDiCU3Y/9g5Y5f1NWVF8W2Oa185gf/j9MYCODUNA
x/yqyxfzWIyYqV3wH/28dAKWCQTXVyXQV2+TvXQ1/UotCKaIym0dG00H5vaibXUs
wscyTjwJScj3jzZBX698EdyuvvxIGeDk9ZesQhHxnguaP0ZYFQZvxtCOX//D0bmD
hbzSLpfhy0ALChwHKLk0wQCTMit1NqceHtqMAYyFzUgxJem8NJ7tSycW7UTZ5li1
N/A+a9rTXZ52ZAznGBPsCskvrU+7qpb3FXdT3AsmHJTN+IOFc+6cJPGebPnb6qFc
nd+Vgcyki4RecxZzzplV81ebQNNaX+B8zc4H/y+F6x2S7Y5hgpXCuXxfLFue37Cv
0MDwFxMGVLyDwnGS+nk+dnRAp1x8IdWo3X/QFhbm+1ZhNACCTIgS6drWSLXNRzAY
bTnIGjeS9FNz2IPDUYMWINiyv1Qpj9reisfj3txMlJuWM+SBEY3AhIbfK87nTRSc
SIGR62GkfMbElYr1FWEeWPcCtxm2a/Qe3kR8ckB+3O2Ml7QKbF+FOnLhfu1TxGzQ
Ntw0LVxx7QPhgWXcct3b+pYsC7p7KtjE+PctM4xfVZdvx8MrD1MfdS5evB4abde2
LKn5CoqZcu74r3EX43ObZl0Ayp4GIIESrvMJay4hIIkEPaR+cm8BpoDzMwARAQAB
tCJQZWRybyBMYW1hcyA8cGVkcm9sYW1hc0BnbWFpbC5jb20+iQJXBBMBCgBBAhsB
BQsJCAcDBRUKCQgLBRYCAwEAAh4BAheAAhkBFiEETHF73A5FL1sTSQQJq0xkxYup
w+0FAmJ9I6cFCQz3YYwACgkQq0xkxYupw+1DKw/7BEDTK4wUFOSGIdvbcZpZ4KTE
t8J4qmp8rXJ56zjRgZhlnn8sPKXxmYeIvsVUlTdY3gafVzn7T/h/uk4v3My4mVNm
qu4qKZdj3C7okX6iwG6Vi2VTD83Pe8lAMvv0wx+dtuKZtwDTNetkAx8xMfBSaa3r
7L3IezJlPxSRhz9XiJkipcdupwF8pEiHx9hqag8ZQZJG5Jg9dn8V7isMuwnN/Eb9
OBJVpvJuQhneZ4td3uF1bxKNWBrpmdY2ZO38Z80nIYp6JNOgIzHO3lzpETH4mF+m
zUNgnRhjoxoNVhcyXsEa9dBWssua/JnhYFgwveDlpRU+xYRDnc4MSxJyzs18qlUP
dOkvbSZ8LbCrYB2Mch6v3V4A0XRx4g2EXVLDLIDh525HQLLuif70g56+UJxKyTdX
u0SMxxGqOXMeo8m7NQmGTzjmFJdczQvc42ekIZtDm+ZmiKZe/4G14oSCtGqjr/pP
yXufff6Bb3QUfiP9hBybwU33BsBM9r4+kNxjYMX5JjlJCdBJjsLqYwONPWy20kya
uAT6AAf4t79c2ytwqgjMRVdAKXUhX8VJm7iSs40muuBGQUk+oaCvI2kfOFrlNN0L
slu86Yd90+7ljkn4hw+JnLYoZSE7Z9KRrh/lDnRjFa7J6LFSw/TlcYmKkfQYlYHX
9igJTfYk2ejRN5YqFCi0JVBlZHJvIExhbWFzIDxwZWRyby5sYW1hc0BvdXRsb29r
LmNvbT6JAlQEEwEKAD4CGwEFCwkIBwMFFQoJCAsFFgIDAQACHgECF4AWIQRMcXvc
DkUvWxNJBAmrTGTFi6nD7QUCYn0jpwUJDPdhjAAKCRCrTGTFi6nD7dxCD/0fcS53
V2idpbAnDW62mEEWtNI+cp3XSh4cHChUfg+10fXAyOPBoSPT+t8nCEYvZSkSKrFg
A41//j3r9udv6Arp99H9kivciQcJWHQTdWIIiukrVCLSuILU26xpaYyrMGxxZrTc
6Gc7YKXcHTToL9rqKNn3iV2KnLYUBHgvA1Q+PTjLvZypIV51CRdbyOSv82F3KW1O
B7DC7O3a9jZfOBhQY8Q+l+qvoy0WkkU6bz4kAtyE0uYqFC99W9wf19kn7+IcCdGn
/Pz+l2pCiwUhdmbKxKGLHRMI2uWCsHFGpw4SiO+K4xZwHpTvTVJ+Phe5vMGHj3dv
sXPuExSgdjbBgNfVip138OTHLUWs3XAyJFEhClI8MAe479ACFxRr5SKPwgepUpJx
BQRhx3biKqb7Czx8fOR3x50216dCrcfQW+LAIaqkxdn1ILp88zGmumz+Q3XnRxKH
eEgWNnZf2bIUydAVn0kAHc5SpS+/h8rR7nY3jvDRaCb51/dthEKmYpsUgONBlhr5
+2rFKErDUTHc6jgZpT634+dB2ftnnSSULhGgluIu9CnWZbr84Y66ysRlr8gWAnyM
cc8iqc7jNvv5ug0kYobyO8UdwaTvqJ5qkMeeVy0IC2TH1fOYiy3127FdUrODLGu5
/Lrk9F+Pd9TH0jraXeIVeULtRY7hkXkgRWdJV7QiUGVkcm8gTGFtYXMgPHBlZHJv
QGFtcGxlcm9hZHMuY29tPokCVAQTAQoAPgIbAQULCQgHAwUVCgkICwUWAgMBAAIe
AQIXgBYhBExxe9wORS9bE0kECatMZMWLqcPtBQJifSOnBQkM92GMAAoJEKtMZMWL
qcPt7xYP/Rw7zBTZpvzW1tpMs0x4O/Iyajs9PnIwr3Di5D7AY8iGu0dkcM6JBynz
sFSAwAM/SgnNL9/DB5agDOkdrohnYvTsPiEvL7zu3WOkgmDSv5eXN6B5iKpdeCu6
8ZDV+BWDflNXZhngb4Ww4bKsxJf+u6hpMtOvOzHXuQuIH7/GvLiPVTFG/2cJMz0S
qv6FMAYeRXm2Ezk+ioPmpGAlajXzABdpOgZ2LiGI2Kso22r5RMmGp7mAWhoupfWm
ILbgqNWn07uttD5bKnMQeMbdCglV+JivaLix+KpJeGCKthEBxpu4N2ZM/3QEklp7
OlYYGlmIj0WA6n+ldRzSAh9+ZdOVdM4RlChb+II9C8Lun/a2nOV3h12BLXDAXREo
Llj9w9/eTQSMiHUq3fI3GnPjBqJ66E8Wv3hkN13VrTBiE+46WvYNdB2bMF4SZos8
shFji7nI7psfk76gAYZaSNRQkgoWR7fxyayT9p/J3BMzu6llPl6sUc0vI3KMjtgy
OK0yBrPTn4FF3jtVDqEpITxLXDcZe46OZr04Vn5+ibWPjHo7jdNZVtD7xtt45h3h
zk8fBNfGDriEXZWgQg37TPJcmgTYbQ7CJL8VPTH3psgJ0smEwzhHJi9MI/T8HI2T
Z0UKdfgc0xGmi6zeLIi5O3RNZ/P+BEclU9hP5cpcVUG37cSauWW6uQINBFlH/FEB
EACyqe6VypUOyx8yzyvX8UG5vXyGHBVjS+ienZTUgtmJ7ktPRIYZE4B2Cp57/6xg
FdcHH7XtcfcE+AggER2qP6Wp67XQ0v2PxkjQZTBtdxCGQ6LUpNACNKrITuH+xPRY
KWKa2X/XuDdDp6b2wgnz3w8Ysl+SCKqa/0LQ7iscPznW1rhk8Uib4DbOadpsJB4M
ukvOSfoIdWBr2SiuOot5Vxj8+EHWB0vDiduh17DvJgZn3MRuaERYEUVLTMo/2ubs
y4todVztpov2K5Z8/5XNE/wOfYDIutzVxO7SqCeiIIM4q77LprfBtmMRJDLY1ShG
ntSDKzI+g47mcM5e6PKxD6o8vEyYa+ebK7DB8EF1giBF7x8YVd3JjvbCWVnh94lY
qI/7C7UFeHXk5AeoEcO/dThEhMJBY928PVRdj/iX3I5xHezefSkGmi65AXfw4b34
vemXOd7zDBYOwW8drDOOQpoWGIzQehAYBSQyAcjYg3vaf8NC21I1rrIisbRR5o1d
GceEQnXb6EP5D5JVLWhhpxtwPZr2dmknim/zxuXPQUbjxLFOFOyOLQiLlUP2QRFj
mP1M+bTSmKQ4j0bQVAw4v770Ju/BV8uMBrYvyZ/CYWA4fdWX+MBDfdr8URoau8sv
U89pOR2XutqbkniEvvhvA0c7SCSD5ciMnTEjpT3uF5MI/wARAQABiQRyBBgBCgAm
AhsCFiEETHF73A5FL1sTSQQJq0xkxYupw+0FAmJ9I7kFCQsU3G8CQMF0IAQZAQoA
HRYhBCDEK2xBofiHxwiawf7PhdFk6EgrBQJZR/xRAAoJEP7PhdFk6EgrixwQAIJJ
9pBcmatf5esY7FSkakqw7RmCnrfjfQGTnFaxkCavYicDEoUD7YXyg2itaJv8dXUe
EuBf1DrdjdCaff3U20QipgF0fy8Sdb6zwZvNy3ZW144Cd91HON6a0TqiLmPWXgxm
n0QdzIM+8T7OhpZVCZDYTJd0aPncamBNbdU5vyrpxZbXVPApU3YH2guDpav34ilq
338k9IWuPQQIQDe0MxaKVC4EZGB0PuNDcVbS1DtuOE6cPEnfWHJvB4KfmNU+BMNw
J1TTuTF3wXHT7Xyy1UTx/y+U1r20GpBWcfj5ZgTYd1GwK6ebSkb4oRyhC8wKcCnK
7LiTv3tfdB2vPi3LYZKkm8rTK5mkXccBcc3NNt+ypFFyUqmZtXcDz/37pTH5rgFg
dEPTvZUlO5rgRCSsnVqt4tu7J9RzOYI9NUSzJ2C+LV5yGREVJ+UeOjCFZ02oLFsC
LnPZaaIaP9EVYPzDqV1+6HPgyhtj35kgixiwprEkMaQipYQqN15b54HkMhorINUN
rpehie5hKG6D77/nDZo4ArRnZzL2kM0OEFBY19HN6K1vKR1AWx4qzbIgxzDJvt1b
6lCLMol2BgfzFR1bw0P32VXl+v/oQiGEh1AoGzMXButwM526aKCHdDZy+fpcbEM8
VXVrcnqHPH1rr1Ci8caJgRHebzxgAZ1Pb9AopB1ECRCrTGTFi6nD7brND/4rlr3P
hxnf5QJmCcvNJTc+RGWbmMrA0wZFxDlRwMprjzdkvDemVdRpokyyJhJnhJ4IfCd3
l67NpH1FG2kmBJEWXPiLjxAtKuAJw/8puMQFCv6QTBHRm3NktxaEgYECV/Pa09Qc
vy1ggEgx2MjrtdsLmvY25/3XIKyJkbsqg2LUYQCGV2z1TbNeELnes92XAYJv0nQv
oKTr1rqudnleFkwv7exC81J9xQtFKeHg96LZgbpVH4u8C0WZsqqYF2S6WRR7rPfz
gK5CFcqsrz5AQHESSfVI9Pvy7Zdr0DcpzJyVfFX9UKiKCc+dG5trg+XhM7jW7sNg
x4Xv/V5sJOSUA5i7W1eJa2cfWXXAh88N0LP/IVP0ffwsQv2umzNLqdgo37/v8nLu
TME+Z1J7WPwEt/yJCqkLJ8qMTRG0kPzIuiklvsFdg4E42XrxsJUTvYB6Nkv+re/0
ZTVDvjaWfzF+9lRXrmCAjNrSvT3FJ4ThO5CVYSe5nbUUCzhMeMdczPr4iJdx3VYJ
Pl8ctaQjqqnQp0436BdVjco2yeo15tdz2xq+DTmMI5ehoVDw1302E+dG965qe/W0
SbsSCD94ARY91nrCc4tc0Cq3Y7zDNokIYR5f7Nz8dyu84zBN1j5fWuqc5n+9/BFf
7guhc/zIsweInDQY6MgF2EJrQvUoxfmgfDtVV7kCDQRZR/xsARAA5GYEdg2+wYit
/JkGHdpC0OVehiWQyDvbo482z2s5lfloa9tAtXDiLZhfKBdriwJ3FTdHOUUoO80i
xWtV2YOrkr40TvumlRxsN8+ScpP2nvDaL2a2nqHKdaNs9UXbGjESIwHCvUQLWgmo
WwPyn4pOBa9szAJjX1SgT4S7KlGmkxEPYmBsn0mIL5bKUhWiLu+dVg9eRR59yQ8D
WvAC0uf4mddoI4g8E+9Bd2GX65ExeIh45GOvNpiZ5kWJVNWbatyCcMNIyf+awmxG
9QhznG9fRkIzDoXek11JaXIjtorFMNZHhDP/2O/GioUHHS7V1pYuBcemYVGKbok8
lAUOrzf6hacIfx4zXA0Sqp2VJuov3t2xSxJ5Kqs8HSAivXSCTNryIF6gxNYsgR14
fsbbfA6scI9gfrYar2mM7fEap23AeMGf8q1guFiuYz9BB+XqL4DB8LmUg0ATQKZZ
K/4jA/KXk73EZ2TJCsN7fyF0Gy5NXoukZxjsdwgy3vR6yHCp76jyUpQNSoaF5WCF
HTuCNTkh9YK4CvuZ8TxJbWUozfbTLDRCrLZ4AIjyTSgggY1VrtO61UERmAGkhfAS
YMN5a7/S62OuXHR0UpHs4d13kCZlBqT2E9Os6PRtiVOk6nGoZh6GwMaryJBYia+Z
7aoHxrWsJBPeyNxgr3Y+ah/CoWYTQQ0AEQEAAYkCPAQYAQoAJgIbDBYhBExxe9wO
RS9bE0kECatMZMWLqcPtBQJifSO/BQkLFNxUAAoJEKtMZMWLqcPtIqAP/1skPueF
wN65i0m/8cwVxjjZ6JVs6RbuekGtcaFXDa1SZ8oRzpjLctMjdfm1/qcWgurnYWv2
4Dberzz0EepfSXGCzYqW6/p7HZtFVQhhuZla8d5Sd/W4KyjQDBbXBoEZFeOdgGX/
xkdhA9D3FXZ6RSyp+bFJJgre1bMaEfte33X3LljkN8gRmtzf1QkAk1n1UWUHmfci
s2CnkIye672XugetfF1rbubFZDeCyxU86WjHE2Z05IHbcS2HgDstiTR1D4yoSy51
JVMUfjvqMJowT0J5mDXGYrDGD+yjes47rpzfH0Qv53g3nTTnvsmMdhbVGgY8eXiq
flncxCIuL6q9f6TSNAurH8XAL3J2aE7InVRGP3kqRDi1mXqwE5OtJ/XOwdofxQcB
nezpHkUo86ki21BjdlxUvWDJ5m5i/zHjCBUCwBQCmsKn9E4uRb1dLc2GD8EXUMMl
NIMgNyRlY5c+qGtOjNPp5oQGZ5sMRf2OYtfZ1D0cvC9eN7iVYTRVSlMvZXXC3XYP
TNQkaVznIqWyDgGN0HSlFAGK4yyGE0Ghoa3+N2i9IF5/X1TB436wUybOwkQUxTSH
2qC0luHUB62kulBMg1u/1XK2JLFSIUEMSHbeFMdUI8iLbLplZ4Ye+yenm+SHhhhY
pcSSAwm3kXFnI3+B6sy8bBTWZMCZnwuIUFdSuQINBFlH/IoBEADWa+Ms+VRM2P+M
0d7SFT+e/CNfcFYHYH1fxMBHz55fwRTRSPufokgcmcC1D6/5oBMnbxKirO+d8bfp
pz0gG/qL9Zbe90E+AekuHckESyWR1EEFGO2LsNTGEmAZYDb9S/RbNQAHxFeA96mc
WwRcaoDVmerpcqYJkKBuD25vCACuuNFD+z82jZQ4olnkoheOSGAv3dRuYG2UoO7A
HBfUy4jcb72IfMex3XpMEF5HVXAW/uRiP7+VjPExg28bTKFbmNYWzeiC9EagdjTJ
sx+DqsJWZTJR/5vB7Vj8kWFaeAwRN/aXhu8EHr1Xp9q2uOO6uLeJ92EPKsOhy+r9
MZmiFJlgFf46zcnPXhhuOserm1fM9ZeDc0BiFp3EaP532rjJCs8vkKXOojoWPFmF
4FM5riXQRLPk+Qib7L5k40GNKzzAtvQS9TJlC3PJCOSuLUCwyvTDcewM67wD7qWQ
1AFhO/dmOUhnhbJeR4m9f+/PGR39ciKduWIvem5LYxoZ24FkVIIbP8UTXc61IiX5
15EDd1GQiHZC2hm3D1tPT0oU4HPFQmj3PiRoNLxByAzcrREU7geuW2y9ulZEPdFk
V2XEiDoeCUxWPyt+6lrmoQ93u9KJ/Ix3oCRZBFUPFMH2w/aG5OPfW5oKY/VCyJ4H
FXH9AvrrbmUPulT6SrUruWCI8WbX3wARAQABiQI8BBgBCgAmAhsgFiEETHF73A5F
L1sTSQQJq0xkxYupw+0FAmJ9I8gFCQsU3DYACgkQq0xkxYupw+1oURAAmK0SNEKs
zHbb+vjFiKCPv4wPtYY3zlaGqwW9q+MAtuja8DFoEoXRkFLRrAQhVGqeXyUCdevZ
qVYQuFSzBdF3ZR8kkjhqI71IDf8Vhrh/j5A5BS2zI+s1EMRAhghPgcBOxH4lU8Qi
ZBmO5WJP/JeaZE7/yys/pCx/XzdoXffW6XTzisfe6wl5i/9DMDnJFjsBw9h3kDHY
yMzpTHkHiT9Y6qMEJIdXDhnXTAAgm9sJ4hacJKHSS2kp5hbC4nb+4UThUgPpo5Yj
w7ae2wwMY9OS5YdV0/+DEVngOjEW9elIoUZRZ5+upod/E76IZZ0D5bTOelBsTR3U
LVDy3jsIGWAUY5cY8K0cr6DcaI1tTpNCdGDxqx22aEuDzcZpKeg0hQVwParcDtgI
kWk5XAq7m+JJVhKy8lpmwIX62NSiAZ4P1jR7r1eYrGNDQV55hnnJecgBwQ260do7
lOqZupZ9bSRJMhRIFikrr4b8czUJ3LXEikpJOuSbWhwEc3vjJddqB6EZyd7WihO5
zXNYrGsIkPenkutHff/920X89bJfzEWkDDdBC6War7drW/rEH6Z+GhGEGYF6/TJW
bsn8KdyhWwQC/kv/fuDX98boLM+2b/h07ocmi44P4UakOyLDU2HUSluACR3CYhIW
bPho7/4ajtJoFbVOavMjWuoLxowjJqArHVg=
=bsQf
-----END PGP PUBLIC KEY BLOCK-----
`}</code></pre>
    <p><a parentName="p" {...{
        "href": "/public.asc"
      }}>{`Download Public Key file`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      